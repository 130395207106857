// @ts-nocheck
import { CircularProgress } from "@mui/material";
import { KTIcon, toAbsoluteUrl } from "_metronic/helpers";
import { Popconfirm, Select, Tag, Tooltip, notification } from "antd";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";
import DateTimeCell from "app/_components/table/cells/DateTimeCell";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import clsx from "clsx";
import { useState } from "react";
import { useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import {
  block_user,
  delete_admin,
  edit_user_type,
  unBlock_user,
} from "../core/_requests";
import UserInfoCell from "app/_components/table/columns/UserInfoCell";
import "./columns.scss";

const user_type = [
  {
    label: "NORMAL",
    value: "1",
    color: "gold",
  },
  {
    label: "KOL",
    value: "2",
    color: "cyan",
  },
  {
    label: "BIZ",
    value: "3",
    color: "blue",
  },
];

const usersColumns: any = (
  hasPermissionDelete: boolean,
  hasPermissionUpdate: boolean
) => [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="PLATFORM"
        useQueryRequest={useQueryRequest}
        className="min-w-150px"
      />
    ),
    id: "platform",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <PlatformCell className="min-w-150px" user={row} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="USER_TYPE"
        useQueryRequest={useQueryRequest}
        className="min-w-150px"
      />
    ),
    id: "user_type",
    Cell: ({ ...props }) => {
      const intl = useIntl();
      const { refetch } = useQueryResponse();
      const [open, setOpen] = useState(false);

      const row = props?.row?.original;
      return (
        // <div className='mini-select'>
        //   <Select
        //     value={row.type.toString()}
        //     onChange={async (value) => {
        //       const res = await edit_user_type(row.id, value);
        //       if (res?.data?.code === 200) refetch();
        //       setOpen(false);
        //     }}
        //     options={user_type.map((i) => {
        //       return {
        //         ...i,
        //         label: intl.formatMessage({ id: i.label }),
        //       };
        //     })}
        //   />
        // </div>
        <div className="select-user-type">
          <Select
            options={user_type.map((i) => {
              return {
                ...i,
                label: intl.formatMessage({ id: i.label }),
              };
            })}
            value={[row.type.toString()]}
            onChange={async (value) => {
              const res = await edit_user_type(row.id, value.pop());
              if (res?.data?.code === 200) refetch();
              setOpen(false);
            }}
            tagRender={(props) => {
              const item = user_type.find((i) => i.value === props.value);
              // console.log('user_type',user_type)
              return <Tag color={item?.color}>{props.label}</Tag>;
            }}
            mode="multiple"
            open={open}
            onDropdownVisibleChange={(visible) => setOpen(visible)}
          />
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="USER"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "user",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <UserInfoCell user={row} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="CREATED-AT"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
        isForceDesc={true}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "created_at",
    accessor: "created_at",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.created_at;
      return <DateTimeCell date={date} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="UPDATED-AT"
        isNoSort={false}
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "updated_at",
    isCenterRow: true,
    accessor: "updated_at",
    Cell: ({ ...props }) => {
      const date = props?.row?.original.updated_at;
      return <DateTimeCell date={date} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        isNoSort={false}
        title="ACCOUNT-REPORT"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "account_report",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const count = props?.row?.original.count_reported;
      return <div>{count}</div>;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        isNoSort={false}
        title="ACCOUNT-BLOCK"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "account_block",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const count = props?.row?.original.count_blocked;
      return <div>{count}</div>;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ACCOUNT-STATUS"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "account_status",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const is_active = props?.row?.original.is_active;
      return <div>{is_active ? "Active" : "In-Active"}</div>;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ACTION"
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),
    id: "action",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const location = useLocation();

      return (
        <div className="d-flex" style={{ gap: 5 }}>
          {hasPermissionDelete && <DeleteComponent row={row} />}

          {row?.is_block && hasPermissionUpdate ? (
            <UnBlockComponent row={row} />
          ) : (
            <BlockComponent row={row} />
          )}

          <Tooltip placement="top" title="User detail">
            <Link
              to={`${location.pathname}/detail/${props.row.original.id}`}
              className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary "
            >
              <KTIcon iconName="arrow-right" className="fs-2" />
            </Link>
          </Tooltip>
        </div>
      );
    },
  },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title="DELETE"
  //       className="min-w-150px"
  //       useQueryRequest={useQueryRequest}
  //     />
  //   ),
  //   id: "action-delete",
  //   Cell: ({ ...props }) => {
  //     const row = props?.row?.original;
  //     return <DeleteComponent row={row} />;
  //   },
  // },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title="DETAIL"
  //       // className="text-end min-w-100px"
  //       useQueryRequest={useQueryRequest}
  //       isCenter={true}
  //     />
  //   ),
  //   id: "actions",
  //   isCenterRow: true,
  //   Cell: ({ ...props }) => (
  //     <ActionsCell
  //       id={props.data[props.row.index].id}
  //       useQueryResponse={useQueryResponse}
  //     />
  //   ),
  // },
];

function DeleteComponent({ row }) {
  const { refetch } = useQueryResponse();
  const intl = useIntl();
  const [isLoadingDeleteUser, setIsLoadingDeleteUser] = useState(false);

  const handleDeleteUser = async () => {
    setIsLoadingDeleteUser(true);
    const res = await delete_admin(row.id);
    setIsLoadingDeleteUser(false);
    if (res.status == 200) {
      notification.success({
        message: intl.formatMessage({ id: "DELETE.USER-SUCCESS" }),
      });
      refetch();
    }
  };

  return (
    <Tooltip placement="top" title="Delete User">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Popconfirm
          title={intl.formatMessage({ id: "DELETE" })}
          description={intl.formatMessage({ id: "USER.DELETE.CONFIRM" })}
          onConfirm={handleDeleteUser}
          onCancel={() => {}}
          okText={intl.formatMessage({ id: "YES" })}
          cancelText={intl.formatMessage({ id: "NO" })}
        >
          <button className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary">
            {isLoadingDeleteUser ? (
              <CircularProgress size={16} />
            ) : (
              <KTIcon iconName="trash" className="fs-2" />
            )}
          </button>
        </Popconfirm>
      </div>
    </Tooltip>
  );
}

const PlatformCell = ({ user, className = "" }) => {
  let imageSrc;
  switch (user?.platform) {
    case "amazon":
      imageSrc = toAbsoluteUrl("/media/social_media/amazon.svg");
      break;

    case "apple":
      imageSrc = toAbsoluteUrl("/media/social_media/apple.svg");
      break;

    case "facebook":
      imageSrc = toAbsoluteUrl("/media/social_media/facebook.svg");
      break;

    case "google":
      imageSrc = toAbsoluteUrl("/media/social_media/google.svg");
      break;

    case "kakao":
      imageSrc = toAbsoluteUrl("/media/social_media/kakao.svg");
      break;

    case "naver":
      imageSrc = toAbsoluteUrl("/media/social_media/naver.svg");
      break;

    default:
      imageSrc = toAbsoluteUrl("/media/logos/picaverse.svg");
  }

  return (
    <div className={`${className} d-flex align-items-center`}>
      {/* begin:: Avatar */}
      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
        <div className={clsx("symbol-label fs-3")}>
          <img src={imageSrc} className="w-50" />
        </div>
      </div>
    </div>
  );
};

const CellOS = ({ row }) => {
  const intl = useIntl();
  const data = row.device_os && row.device_os.split(",");
  const convertData =
    data &&
    data.length > 1 &&
    data.map((item) => intl.formatMessage({ id: `${item?.toUpperCase()}` }));

  return (
    <div>
      {row.device_os
        ? convertData.length > 0
          ? convertData.toString()
          : intl.formatMessage({ id: `${row.device_os?.toUpperCase()}` })
        : ""}
    </div>
  );
};

// const UserInfoCell = ({ user, className, row }) => {
//   const location = useLocation();

//   return (
//     <div className={`d-flex align-items-center ${className}`}>
//       {/* begin:: Avatar */}
//       <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
//         {user?.profile?.profile_image_url ? (
//           <div className="symbol-label">
//             <div
//               className={`align-self-end my-auto h-100 w-100`}
//               style={{
//                 backgroundImage: `url(${user?.profile?.profile_image_url})`,
//                 backgroundPosition: "center",
//                 backgroundSize: "cover",
//               }}
//             ></div>
//           </div>
//         ) : (
//           <div className={clsx("symbol-label fs-3")}>
//             <img
//               src={toAbsoluteUrl("/media/avatars/blank.png")}
//               className="w-100"
//             />
//           </div>
//         )}
//       </div>
//       <div className="d-flex flex-column">
//         <Link
//           to={`${location.pathname}/detail/${user.id}`}
//           className="text-gray-800 text-hover-primary mb-1"
//         >
//           {user?.name}
//           <span>{user?.email}</span>
//         </Link>
//       </div>
//     </div>
//   );
// };

function BlockComponent({ row }: any) {
  const { refetch } = useQueryResponse();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const handleConfirm = async () => {
    setIsLoading(true);
    const res = await block_user(row.id);
    setIsLoading(false);
    if (res.status == 200) {
      notification.success({
        message: intl.formatMessage({ id: "BLOCK-SUCCESS" }),
      });
      refetch();
    }
  };
  return (
    <Tooltip placement="top" title="Block User">
      <button className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary">
        <Popconfirm
          title={intl.formatMessage({ id: "BLOCK" })}
          description={intl.formatMessage({ id: "USER.BLOCK.CONFIRM" })}
          onConfirm={handleConfirm}
          onCancel={() => {}}
          okText={intl.formatMessage({ id: "YES" })}
          cancelText={intl.formatMessage({ id: "NO" })}
        >
          <button className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary">
            {isLoading ? (
              <CircularProgress size={16} />
            ) : (
              <KTIcon
                iconType="solid"
                iconName="toggle-off-circle"
                className="fs-1"
              />
            )}
          </button>
        </Popconfirm>
      </button>
    </Tooltip>
  );
}

function UnBlockComponent({ row }: any) {
  const { refetch } = useQueryResponse();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const handleConfirm = async () => {
    setIsLoading(true);
    const res = await unBlock_user(row.id);
    setIsLoading(false);
    if (res.status == 200) {
      notification.success({
        message: intl.formatMessage({ id: "UN-BLOCK-SUCCESS" }),
      });
      refetch();
    }
  };
  return (
    <Tooltip placement="top" title="UnBlock User">
      <button className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary">
        <Popconfirm
          title={intl.formatMessage({ id: "UN-BLOCK" })}
          description={intl.formatMessage({ id: "USER.UN-BLOCK.CONFIRM" })}
          onConfirm={handleConfirm}
          onCancel={() => {}}
          okText={intl.formatMessage({ id: "YES" })}
          cancelText={intl.formatMessage({ id: "NO" })}
        >
          <button className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary">
            {isLoading ? (
              <CircularProgress size={16} />
            ) : (
              <KTIcon
                iconType="solid"
                iconName="toggle-on-circle"
                className="fs-1 text-primary"
              />
            )}
          </button>
        </Popconfirm>
      </button>
    </Tooltip>
  );
}

export { usersColumns };
