import CSPage from "app/_modules/CS";
import Dashboard from "app/_modules/dashboard";
import RankingPage from "app/_modules/ranking-management";
import UserPage from "app/_modules/user-management";
import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";

import { QueryRequestProvider } from "app/_modules/dashboard/components/core/QueryRequestProvider";
import { QueryResponseProvider } from "app/_modules/dashboard/components/core/QueryResponseProvider";
import EventPage from "app/_modules/event";
import NoticePageWrapper from "app/_modules/notice-management";
import NotificationLogWrapper from "app/_modules/alarm-managenment/pages/notification-log";
import ProfilePage from "app/_modules/profile/ProfilePage";
import ListShareUserProfile from "app/_modules/profile/components/ListShareUserProfile";
import ListPostUserProfileWrapper from "app/_modules/profile/components/ListPostUserProfile";

const PrivateRoutes = () => {
  // const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  // const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  // const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  // const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  const UserManagementModule = lazy(
    () => import("app/_modules/user-management")
  );

  const Term = lazy(() => import("app/_modules/setting-management/term"));

  const ContentModule = lazy(() => import("app/_modules/content-management"));

  const ContentCrawlModule = lazy(
    () => import("app/_modules/content-crawl-management")
  );

  const ProductModule = lazy(() => import("app/_modules/product-management"));

  const CategoriesModule = lazy(
    () => import("app/_modules/categories-management")
  );
  const WithdrawModule = lazy(() => import("app/_modules/withdraw"));

  const PurchaseHistoryModule = lazy(
    () => import("app/_modules/purchase-history")
  );

  const AdminManagementModule = lazy(
    () => import("app/_modules/admin-management")
  );

  const NotificationManagementModule = lazy(
    () => import("app/_modules/notification")
  );

  const SettingManagementModule = lazy(
    () => import("app/_modules/setting-management")
  );

  const FeedDisplaySettingModule = lazy(
    () => import("app/_modules/feed-display-setting")
  );

  const SimulationModule = lazy(() => import("app/_modules/simulation"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/" />} />
        <Route
          path="dashboard/*"
          element={
            <QueryRequestProvider>
              <QueryResponseProvider>
                <Dashboard />
              </QueryResponseProvider>
            </QueryRequestProvider>
          }
        />
        {/*  <Route path="contents/*" element={<ContentsPage />} /> */}
        <Route path="user/*" element={<UserPage />} />
        <Route path="ranking/*" element={<RankingPage />} />
        <Route
          path="alarm-management/*"
          children={
            <>
              <Route
                path="event/*"
                element={
                  <SuspensedView>
                    <EventPage />
                  </SuspensedView>
                }
              />
              <Route
                path="push-management/*"
                element={
                  <SuspensedView>
                    <NotificationManagementModule />
                  </SuspensedView>
                }
              />
              <Route
                path="notice-management/*"
                element={
                  <SuspensedView>
                    <NoticePageWrapper />
                  </SuspensedView>
                }
              />
              <Route
                path="notification-log/*"
                element={
                  <SuspensedView>
                    <NotificationLogWrapper />
                  </SuspensedView>
                }
              />
            </>
          }
        />
        <Route path="cs/*" element={<CSPage />} />

        <Route
          path="user-management/*"
          element={
            <SuspensedView>
              <UserManagementModule />
            </SuspensedView>
          }
        />
        {/*        <Route
          path="user-detail/:id/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        /> */}

        <Route
          path="content/*"
          element={
            <SuspensedView>
              <ContentModule />
            </SuspensedView>
          }
        />

        <Route
          path="crawl/*"
          element={
            <SuspensedView>
              <ContentCrawlModule />
            </SuspensedView>
          }
        />

        <Route
          path="product/*"
          element={
            <SuspensedView>
              <ProductModule />
            </SuspensedView>
          }
        />

        <Route
          path="categories/*"
          element={
            <SuspensedView>
              <CategoriesModule />
            </SuspensedView>
          }
        />

        <Route
          path="withdraw/*"
          element={
            <SuspensedView>
              <WithdrawModule />
            </SuspensedView>
          }
        />

        <Route
          path="purchase-history/*"
          element={
            <SuspensedView>
              <PurchaseHistoryModule />
            </SuspensedView>
          }
        />

        <Route
          path="admin-management/*"
          element={
            <SuspensedView>
              <AdminManagementModule />
            </SuspensedView>
          }
        />

        <Route
          path="setting-management/*"
          element={
            <SuspensedView>
              <SettingManagementModule />
            </SuspensedView>
          }
        />

        <Route
          path="simulation/*"
          element={
            <SuspensedView>
              <SimulationModule />
            </SuspensedView>
          }
        />

        <Route
          path="feed-display-setting/*"
          element={
            <SuspensedView>
              <FeedDisplaySettingModule />
            </SuspensedView>
          }
        />

        <Route
          path="123"
          element={
            <SuspensedView>
              <Term />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
      "1.0": baseColor,
    },
    barThickness: 3,
    shadowBlur: 3,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
